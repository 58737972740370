import React from "react";
import "./style.css";
import { contactConfig } from "../content_option";
import { Socialicons } from "../components/socialicons";

export const Footer = () => (
  <footer className="footer">
    <div className="footer-contact">
      <p>E: {contactConfig.YOUR_EMAIL}</p>
      <p>T: {contactConfig.YOUR_FONE}</p>
    </div>
    <div className="footer-social">
      <Socialicons className="footer-socialicons" />
    </div>
  </footer>
);
