import truckTracker from "./images/truckTrackerImage.png";
import shiftCalculator from "./images/shiftTracker.png";

const logotext = "STEFAN";
const meta = {
  title: "Stefan Kovacevic",
  description:
    "I’m Stefan Kovacevic software developer _ Full stack developer, currently working in Canada",
};

const introdata = {
  title: "I'm Stefan Kovacevic",
  animated: {
    first: "Full-Stack Developer",
    second: "C# and .NET Specialist",
    third: "Building Digital Solutions",
  },
};

const dataabout = {
  title: "abit about my self",
  aboutme:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent quis dolor id ligula semper elementum feugiat pretium nulla. Nunc non commodo dolor. Nunc mollis dignissim facilisis. Morbi ut magna ultricies.",
};

const dataportfolio = [
  {
    img: truckTracker,
    description:
      "Truck Tracker – Built using ASP.NET Core MVC, this app manages daily records, divisions, and invoicing for construction companies, enhancing operational efficiency.",
    link: "https://github.com/s7kovace/Truck-Tracker",
  },
  {
    img: shiftCalculator,
    description:
      "Shift Calculator App – Developed with .NET MAUI and SQLite, this app helps healthcare professionals calculate shift earnings, including premiums for nights, evenings, and weekends, with tax deductions.",
    link: "https://github.com/s7kovace/CalculatorApp",
  },
];

const contactConfig = {
  YOUR_EMAIL: "s7kovace@gmail.com",
  YOUR_FONE: "+1 (519)722-1531",
  description:
    "Feel free to reach out via email or phone. Whether you have questions, need assistance, or just want to connect, I'm here to help. Let's get in touch!",
  // creat an emailjs.com account
  // check out this tutorial https://www.emailjs.com/docs/examples/reactjs/
  YOUR_SERVICE_ID: "service_ck23dil",
  YOUR_TEMPLATE_ID: "template_2y191hq",
  YOUR_USER_ID: "EVFxo3ROVjNwOKhI_",
};

const socialprofils = {
  github: "https://github.com/s7kovace",
  linkedin: "https://www.linkedin.com/in/skovaceviccpa/",
  x: "https://twitter.com",
};
export {
  meta,
  dataabout,
  dataportfolio,
  introdata,
  contactConfig,
  socialprofils,
  logotext,
};
