// About.js
import React, { useEffect, useState } from "react";
import "./style.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import {
  CsharpOriginal,
  JavaOriginal,
  JavascriptOriginal,
  Html5Original,
  Css3Original,
  DotnetcoreOriginal,
  ReactOriginalWordmark,
  GitOriginal,
  GithubOriginal,
  GraphqlPlain,
  AzureOriginal,
  DockerOriginal,
  VisualstudioPlain,
  RabbitmqOriginal,
  PostmanPlain,
  SwaggerOriginal,
  VscodePlain,
} from "devicons-react";
import {
  FaChess,
  FaFutbol,
  FaRunning,
  FaPlane,
  FaBriefcase,
  FaGraduationCap,
} from "react-icons/fa";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { FaDatabase, FaChevronDown } from "react-icons/fa";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

export const About = () => {
  // Initialize AOS library
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const [showScrollIndicator, setShowScrollIndicator] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 0) {
        setShowScrollIndicator(false);
      } else {
        setShowScrollIndicator(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <HelmetProvider>
      <section id="about" className="about-section">
        <Helmet>
          <meta charSet="utf-8" />
          <title>About | Stefan Kovacevic</title>
          <meta
            name="description"
            content="Learn more about Stefan Kovacevic, a passionate Full-Stack Developer specializing in C# and .NET technologies."
          />
        </Helmet>
        <div className="about-container">
          {/* About Me Section */}
          <div className="about-me-section">
            <h2 className="section-title" data-aos="fade-up">
              About Me
            </h2>
            <p className="about-text" data-aos="fade-up">
              Hello! I'm Stefan Kovacevic, a dedicated Full-Stack Developer
              based in Kitchener, Ontario. With a strong foundation in both
              back-end and front-end development, I specialize in creating
              robust and scalable applications. My expertise lies in C#, .NET
              technologies, and modern JavaScript frameworks.
            </p>
            <p className="about-text" data-aos="fade-up">
              I have a passion for problem-solving and a keen eye for detail.
              Whether it's designing system architectures or optimizing code
              performance, I thrive on challenges that push me to learn and
              grow. My goal is to build solutions that not only meet client
              needs but also enhance user experiences.
            </p>
            <p className="about-text" data-aos="fade-up">
              Outside of coding, I’m a chess player and sports enthusiast. I
              enjoy both playing and watching tennis, basketball, and soccer.
              Staying active is important to me, and my ultimate goal is to
              complete a marathon. I also love traveling and am eager to explore
              more countries in North and South America.
            </p>
          </div>

          {/* Timeline Section */}
          <div className="experience-section">
            <h2 className="section-title" data-aos="fade-up">
              Professional Experience
            </h2>

            <VerticalTimeline layout="1-column-left">
              <VerticalTimelineElement
                date="May 2023 – Present"
                iconStyle={{
                  background: "var(--primary-color)",
                  color: "var(--secondary-color)",
                }}
                icon={<FaBriefcase />}
              >
                <h3 className="vertical-timeline-element-title">
                  Software Developer
                </h3>
                <div className="vertical-timeline-element-subtitle">
                  <h4>Brock Solutions</h4>
                  <p>Kitchener, Ontario, Canada</p>
                </div>
                <ul>
                  <li>
                    Developed backend solutions for the SmartSuite Baggage
                    Information Display System (BIDS).
                  </li>
                  <li>
                    Implemented solutions following functional specifications
                    and handled testing, deployment, and support.
                  </li>
                  <li>
                    Mentored junior developers through code reviews, pairing,
                    and collaborative troubleshooting.
                  </li>
                  <li>
                    Applied DDD and TDD patterns to align software with business
                    needs.
                  </li>
                </ul>
              </VerticalTimelineElement>

              <VerticalTimelineElement
                date="January 2022 – August 2022"
                iconStyle={{
                  background: "var(--primary-color)",
                  color: "var(--secondary-color)",
                }}
                icon={<FaBriefcase />}
              >
                <h3 className="vertical-timeline-element-title">
                  Programmer Analyst (Co-op)
                </h3>
                <div className="vertical-timeline-element-subtitle">
                  <h4>Waterloo Regional Police Service</h4>
                  <p>Kitchener, Ontario, Canada</p>
                </div>
                <ul>
                  <li>
                    Developed custom web applications based on management input.
                  </li>
                  <li>
                    Maintained and improved existing applications and features.
                  </li>
                  <li>
                    Conducted unit and system testing to ensure application
                    stability.
                  </li>
                  <li>
                    Created data extraction scripts to provide valuable insights
                    to senior management.
                  </li>
                </ul>
              </VerticalTimelineElement>

              <VerticalTimelineElement
                date="September 2021 – December 2021"
                iconStyle={{
                  background: "var(--primary-color)",
                  color: "var(--secondary-color)",
                }}
                icon={<FaBriefcase />}
              >
                <h3 className="vertical-timeline-element-title">
                  Software Developer Intern (Co-op)
                </h3>
                <div className="vertical-timeline-element-subtitle">
                  <h4>Descartes Systems Group</h4>
                  <p>Waterloo, Ontario, Canada</p>
                </div>
                <ul>
                  <li>
                    Developed and maintained SaaS solutions for high-volume
                    environments.
                  </li>
                  <li>
                    Diagnosed and resolved application issues to ensure smooth
                    performance.
                  </li>
                  <li>
                    Designed solutions aligned with company standards for
                    multi-tenant SOA/SaaS environments.
                  </li>
                </ul>
              </VerticalTimelineElement>

              <VerticalTimelineElement
                date="May 2021 – August 2021"
                iconStyle={{
                  background: "var(--primary-color)",
                  color: "var(--secondary-color)",
                }}
                icon={<FaBriefcase />}
              >
                <h3 className="vertical-timeline-element-title">
                  PC Support Technician (Co-op)
                </h3>
                <div className="vertical-timeline-element-subtitle">
                  <h4>Waterloo Regional Police Service</h4>
                  <p>Kitchener, Ontario, Canada</p>
                </div>
                <ul>
                  <li>
                    Identified and initiated resolutions to client problems with
                    IT equipment, hardware, and software.
                  </li>
                  <li>
                    Monitored event tracking consoles to identify and resolve
                    emerging network issues.
                  </li>
                  <li>
                    Installed, upgraded, and tested computer equipment according
                    to department standards.
                  </li>
                </ul>
              </VerticalTimelineElement>

              <VerticalTimelineElement
                date="January 2020 – April 2023"
                iconStyle={{
                  background: "var(--primary-color)",
                  color: "var(--secondary-color)",
                }}
                icon={<FaGraduationCap />}
              >
                <h3 className="vertical-timeline-element-title">
                  Computer Programmer/Analyst (Co-op)
                </h3>
                <div className="vertical-timeline-element-subtitle">
                  <h4>Conestoga College</h4>
                  <p>Kitchener, Ontario, Canada</p>
                </div>
                <ul>
                  <li>Graduated with high distinction (GPA: 3.90).</li>
                  <li>
                    Won 2nd place in a capstone competition with the "SmartMark"
                    project.
                  </li>
                  <li>
                    Mastered object-oriented analysis, design, and agile
                    methodologies.
                  </li>
                </ul>
              </VerticalTimelineElement>
            </VerticalTimeline>
          </div>

          {/* Skills and Tools Section */}
          <div className="skills-section">
            <h2 className="section-title" data-aos="fade-up">
              Skills & Expertise
            </h2>

            {/* Languages */}
            <div className="skills-category" data-aos="fade-up">
              <h3>Languages</h3>
              <div className="skills-list">
                <div className="skill-item">
                  <CsharpOriginal size="48" />
                  <p>C#</p>
                </div>
                <div className="skill-item">
                  <JavaOriginal size="48" />
                  <p>Java</p>
                </div>
                <div className="skill-item">
                  <JavascriptOriginal size="48" />
                  <p>JavaScript</p>
                </div>
                <div className="skill-item">
                  <Html5Original size="48" />
                  <p>HTML5</p>
                </div>
                <div className="skill-item">
                  <Css3Original size="48" />
                  <p>CSS3</p>
                </div>
                <div className="skill-item">
                  <FaDatabase size="48" />
                  <p>SQL</p>
                </div>
              </div>
            </div>

            {/* Frameworks & Libraries */}
            <div className="skills-category" data-aos="fade-up">
              <h3>Frameworks & Libraries</h3>
              <div className="skills-list">
                <div className="skill-item">
                  <DotnetcoreOriginal size="48" />
                  <p>ASP.NET Core</p>
                </div>
                <div className="skill-item">
                  <ReactOriginalWordmark size="48" />
                  <p>React</p>
                </div>
                <div className="skill-item">
                  <img
                    src="https://cdn.worldvectorlogo.com/logos/jquery-1.svg"
                    alt="jQuery"
                    width="48"
                    height="48"
                  />
                  <p>jQuery</p>
                </div>
                <div className="skill-item">
                  <GraphqlPlain size="48" color="#E10098" />
                  <p>GraphQL</p>
                </div>
                <div className="skill-item">
                  <RabbitmqOriginal size="48" />
                  <p>RabbitMQ</p>
                </div>
                <div className="skill-item">
                  <img
                    src="https://cdn.worldvectorlogo.com/logos/bootstrap-4.svg"
                    alt="Bootstrap"
                    width="48"
                  />
                  <p>Bootstrap</p>
                </div>
              </div>
            </div>

            {/* Tools & Platforms */}
            <div className="skills-category" data-aos="fade-up">
              <h3>Tools & Platforms</h3>
              <div className="skills-list">
                <div className="skill-item">
                  <GitOriginal size="48" />
                  <p>Git</p>
                </div>
                <div className="skill-item">
                  <GithubOriginal size="48" />
                  <p>GitHub</p>
                </div>
                <div className="skill-item">
                  <AzureOriginal size="48" />
                  <p>Azure DevOps</p>
                </div>
                <div className="skill-item">
                  <DockerOriginal size="48" />
                  <p>Docker</p>
                </div>
                <div className="skill-item">
                  <VisualstudioPlain size="48" />
                  <p>Visual Studio</p>
                </div>
                <div className="skill-item">
                  <VscodePlain size="48" />
                  <p>VSCode</p>
                </div>
                <div className="skill-item">
                  <PostmanPlain size="48" />
                  <p>Postman</p>
                </div>
                <div className="skill-item">
                  <SwaggerOriginal size="48" />
                  <p>Swagger</p>
                </div>
              </div>
            </div>

            {/* Methodologies */}
            <div className="skills-category" data-aos="fade-up">
              <h3>Methodologies</h3>
              <div className="methodologies-list">
                <span className="methodology-item">Agile</span>
                <span className="methodology-item">Scrum</span>
                <span className="methodology-item">
                  Domain-Driven Design (DDD)
                </span>
                <span className="methodology-item">
                  Test-Driven Development (TDD)
                </span>
              </div>
            </div>
          </div>

          {/* Interests Section */}
          <div className="interests-section">
            <h2 className="section-title" data-aos="fade-up">
              Interests
            </h2>
            <div className="interests-list" data-aos="fade-up">
              {/* Chess */}
              <div className="interest-item">
                <FaChess
                  size="48"
                  className="interest-icon"
                  data-tooltip-id="chessTooltip"
                />
                <p>Chess</p>
              </div>
              <Tooltip id="chessTooltip" place="bottom" clickable interactive>
                <span>
                  Feel free to add me on{" "}
                  <a
                    href="https://www.chess.com/member/s7kovace"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "inherit", textDecoration: "underline" }}
                  >
                    Chess.com
                  </a>
                  .
                </span>
              </Tooltip>

              {/* Sports */}
              <div className="interest-item">
                <FaFutbol
                  size="48"
                  className="interest-icon"
                  data-tooltip-id="sportsTooltip"
                />
                <p>Sports</p>
              </div>
              <Tooltip id="sportsTooltip" place="bottom">
                <span>
                  I love watching and playing tennis, basketball, and soccer,
                  although I am always open and interested in trying new sports.
                </span>
              </Tooltip>

              {/* Staying Active */}
              <div className="interest-item">
                <FaRunning
                  size="48"
                  className="interest-icon"
                  data-tooltip-id="activeTooltip"
                />
                <p>Staying Active</p>
              </div>
              <Tooltip id="activeTooltip" place="bottom">
                <span>
                  My lifelong goal is to run a marathon. I completed a half
                  marathon in 2018 and look forward to achieving the full
                  distance.
                </span>
              </Tooltip>

              {/* Traveling */}
              <div className="interest-item">
                <FaPlane
                  size="48"
                  className="interest-icon"
                  data-tooltip-id="travelTooltip"
                />
                <p>Traveling</p>
              </div>
              <Tooltip id="travelTooltip" place="bottom">
                <span>
                  I've been to many countries in Europe, and now I want to visit
                  more places across North and South America.
                </span>
              </Tooltip>
            </div>
          </div>
        </div>

        {/* Scroll Indicator */}
        {showScrollIndicator && (
          <div className="scroll-indicator" aria-label="Scroll down">
            <FaChevronDown size="32" />
          </div>
        )}
      </section>
    </HelmetProvider>
  );
};
