import React, { useState, useRef, useEffect } from "react";
import "./style.css";
import { Link } from "react-router-dom";
import { logotext } from "../content_option";
import Themetoggle from "../components/themetoggle";
import useOnClickOutside from "../hooks/useOnClickOutside";
import resume from "../Stefan_Kovacevic_Resume.pdf";
const Headermain = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const burgerRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      burgerRef.current &&
      !burgerRef.current.contains(event.target)
    ) {
      setIsMenuOpen(false);
    }
  };

  useOnClickOutside(menuRef, handleClickOutside);

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsMenuOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <header className="fixed-top site__header">
        <div className="header-container">
          {/* Logo */}
          <Link className="navbar-brand nav_ac" to="/">
            {logotext}
          </Link>

          <div className="header-right">
            {/* Desktop Menu */}
            <nav className="nav__desktop">
              <ul className="nav__list">
                <li className="theme-toggle-item">
                  <Themetoggle />
                </li>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/about">About</Link>
                </li>
                <li>
                  <Link to="/portfolio">Portfolio</Link>
                </li>
                <li>
                  <a href={resume} download="Stefan_Kovacevic_Resume.pdf">
                    Resume
                  </a>
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
            </nav>
          </div>

          {/* Hamburger / X Icon for Mobile */}
          <div
            className="hamburger"
            ref={burgerRef} // Attach ref to the burger icon
            onClick={toggleMenu}
          >
            {isMenuOpen ? "✕" : "☰"}
          </div>
        </div>

        {/* Mobile Menu */}
        <div
          ref={menuRef}
          className={`nav__mobile ${isMenuOpen ? "open" : "closed"}`}
        >
          <ul className="nav__list">
            <li className="theme-toggle-item">
              <Themetoggle />
            </li>
            <li>
              <Link to="/" onClick={() => setIsMenuOpen(false)}>
                Home
              </Link>
            </li>
            <li>
              <Link to="/about" onClick={() => setIsMenuOpen(false)}>
                About
              </Link>
            </li>
            <li>
              <Link to="/portfolio" onClick={() => setIsMenuOpen(false)}>
                Portfolio
              </Link>
            </li>
            <li>
              <a href={resume} download="Stefan_Kovacevic_Resume.pdf">
                Resume
              </a>
            </li>
            <li>
              <Link to="/contact" onClick={() => setIsMenuOpen(false)}>
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </header>
    </>
  );
};

export default Headermain;
