import React from "react";
import "./style.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Typewriter from "typewriter-effect";
import { introdata, meta } from "../../content_option";
import { Link } from "react-router-dom";
import stefanProfile from "../../images/stefanProfile.jpg";
import resume from "../../Stefan_Kovacevic_Resume.pdf";

export const Home = () => {
  return (
    <HelmetProvider>
      <section id="home" className="home">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{meta.title}</title>
          <meta name="description" content={meta.description} />
        </Helmet>

        <div className="intro-container">
          {/* Profile Photo */}
          <img
            src={stefanProfile}
            alt="Stefan Kovacevic"
            className="profile-photo"
          />

          {/* Greeting */}
          <p className="intro-hello">
            Hi there! <span className="wave-emoji">👋</span>
          </p>

          {/* Name and Typewriter Effect */}
          <h1 className="intro-title">I'm Stefan Kovacevic</h1>

          <h2 className="intro-subtitle">
            <Typewriter
              options={{
                strings: [
                  introdata.animated.first,
                  introdata.animated.second,
                  introdata.animated.third,
                ],
                autoStart: true,
                loop: true,
                deleteSpeed: 10,
              }}
            />
          </h2>

          {/* Introduction Text */}
          <p className="intro-text">
            I am a software developer currently working at Brock Solutions,
            based in Ontario, Canada 🍁. I specialize in building scalable,
            efficient software solutions for both front-end and back-end
            systems. I am passionate about developing high-quality software that
            solves real-world challenges.
          </p>

          {/* Buttons */}
          <div className="intro_btn-action">
            <a
              href={resume}
              download="Stefan_Kovacevic_Resume.pdf"
              className="text_2"
            >
              <div id="button_p" className="ac_btn btn">
                Download Resume
                <div className="ring one"></div>
                <div className="ring two"></div>
                <div className="ring three"></div>
              </div>
            </a>
            <Link to="/contact">
              <div id="button_h" className="ac_btn btn">
                Contact Me
                <div className="ring one"></div>
                <div className="ring two"></div>
                <div className="ring three"></div>
              </div>
            </Link>
          </div>
        </div>
      </section>
    </HelmetProvider>
  );
};
