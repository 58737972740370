import { useEffect } from "react";
import { useLocation, useNavigationType } from "react-router-dom";

const useScrollToTop = () => {
  const location = useLocation(); // Track route changes
  const navigationType = useNavigationType(); // Track navigation type (PUSH, POP, etc.)

  useEffect(() => {
    // Scroll to the top whenever the location changes or the same page is reloaded
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Instant for back/forward, smooth for new routes
    });
  }, [location, navigationType]); // Re-run on location or navigation type change
};

export default useScrollToTop;
