import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./routes";
import Headermain from "../header";
import { Footer } from "../footer";
import ScrollToTopWrapper from "../ScrollToTopWrapper";
import "./App.css";

export default function App() {
  return (
    <Router>
      <ScrollToTopWrapper>
        <div className="app-container no-caret">
          <Headermain />
          <div className="main-content">
            <AppRoutes />
          </div>
          <Footer />
        </div>
      </ScrollToTopWrapper>
    </Router>
  );
}
