// AppRoutes.js
import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { Home } from "../pages/home";
import { Portfolio } from "../pages/portfolio";
import { ContactUs } from "../pages/contact";
import { About } from "../pages/about";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Socialicons } from "../components/socialicons";

function AnimatedRoutes() {
  const location = useLocation();

  return (
    <div className="route-wrapper">
      <Socialicons />

      {/* Animated route transitions */}
      <TransitionGroup className="transition-group">
        <CSSTransition
          key={location.pathname}
          timeout={400}
          classNames="page"
          unmountOnExit
        >
          <div className="route-section">
            <Routes location={location}>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/portfolio" element={<Portfolio />} />
              <Route path="/contact" element={<ContactUs />} />
              <Route path="*" element={<Home />} />
            </Routes>
          </div>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
}

export default AnimatedRoutes;
